import React from "react";
import {maxiGet} from "./core/maxios";
import {encodeGetParams} from "./core/helpers";


export const loadHrEintrag = (key, kind, number, court, setCoreData, setStatusVar) => maxiGet("/handelsregister?" + encodeGetParams({kind, number, court}), {setStatusVar}).then(r => {
    setCoreData(r)
    localStorage.setItem(key, JSON.stringify(r))
})


export const R = ({children, ...props}) => <span style={{color: "red"}} {...props}>{children}</span>
export const G = ({children, ...props}) => <R style={{color: "green"}} {...props}>{children}</R>
