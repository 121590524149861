import React, {useMemo, useRef, useState} from "react";
import Status from "./core/status";
import {MyModal} from "./core/modal";
import {Container, Loader} from "./core/components";
import {Check} from "./check";
import {loadHrEintrag} from "./helpers";
import {ShowEntityData} from "./showEntityData";


export function ShowMainData({kind, number, court, tree, setTree, loadData, reloadKey, parent, main, children}) {
    const key = `hreintrag|${kind}|${number}|${court}`
    const parentKey = `hreintrag|${parent.kind}|${parent.number}|${parent.court}`
    const [previousReloadKey, setPreviousReloadKey] = useState(0)

    const [coreData, setCoreData] = useState({})
    const [parentCoreData, setParentCoreData] = useState({})
    const [{loading, error}, setStatusVar] = useState({})

    const ref = useRef();

    const onChange = (name) => {
        return {
            value: tree[name],
            onChange: e => {
                setTree(b => ({...b, [name]: e.target.type === "checkbox" ? e.target.checked : e.target.value}))
            },
            ref,
            checked: typeof tree[name] === "boolean" ? tree[name] : undefined,
        }
    }

    useMemo(() => {

        const local = localStorage.getItem(key)
        if ((loadData && local === null) || (reloadKey !== previousReloadKey)) {
            console.log("realod", key, reloadKey)
            loadHrEintrag(key, kind, number, court, setCoreData, setStatusVar)
            setPreviousReloadKey(reloadKey)
        } else {
            setCoreData(JSON.parse(local))
        }


    }, [key, loadData, reloadKey])

    useMemo(() => {

        const local = localStorage.getItem(parentKey)
        if (local !== null && parent) {
            setParentCoreData(JSON.parse(local))
        }


    }, [parentKey, reloadKey])

    return <>
        <h3> {coreData?.stammdaten?.name}</h3>

        {children}
        {error && <span style={{color: "red"}}>{error}</span>}


        {
            coreData?.stammdaten && <MyModal trigger={<button>Daten anzeigen</button>}>
                <ShowEntityData coreData={coreData}/>
            </MyModal>
        }

        <Loader loading={loading}/>

        {
            !main && <p style={{margin: "5px 0", backgroundColor: tree.holdingAmount > 50 ? "rgba(0,255,0,0.2)" : "rgba(255,0,0,0.2)", padding: 6}}>

                <label>
                    Beteiligung der Mutter-Gesellschaft: <input {...onChange("holdingAmount")}/> Prozent
                </label>
                <br/>
                {
                    !!tree.holdingAmount && tree.holdingAmount <= 50 && <span style={{color: "red", margin: "5px 0"}}>
                    Eine Mehrheitsbeteiligung ist notwendige Voraussetzung für die Bildung einer USt-Organschaft.
                </span>
                }
                {
                    !!tree.holdingAmount && (tree.holdingAmount <= 0 || tree.holdingAmount > 100) && <span style={{color: "red", margin: "5px 0"}}>
                    Falsche Eingabe, es wurde kein gültiger Wert
                </span>
                }
            </p>
        }

        {
            coreData?.stammdaten && <p style={{margin: "5px 0", backgroundColor: tree.ustUnternehmer ? "rgba(0,255,0,0.2)" : "rgba(255,0,0,0.2)", padding: 6}}>
                <label>
                    Ist USt-Unternehmer: <input type={"checkbox"} {...onChange("ustUnternehmer")}/><br/>
                </label>
                {
                    !tree.ustUnternehmer && <span style={{color: "red", margin: "5px 0"}}>
                        Diese Entität muss notwendigerweise ein USt-Unternehmer sein.
                    </span>
                }
            </p>
        }
        {
            coreData?.stammdaten && <p style={{margin: "5px 0", backgroundColor: tree.keineVerflechtungen ? "rgba(0,255,0,0.2)" : "rgba(255,0,0,0.2)", padding: 6}}>
                <label>
                    Keine wirtschaftlichen Verflechtungen vorhanden? <input type={"checkbox"} {...onChange("keineVerflechtungen")}/><br/>
                </label>
                {
                    !tree.keineVerflechtungen && <span style={{color: "red", margin: "5px 0"}}>
                       Wirtschaftliche Verflechtungen sind ein Ausschlussgrund.
                    </span>
                }
            </p>
        }

        {/*JSON.stringify(coreData)}
        {JSON.stringify(parentCoreData)*/}

        <Check child={coreData} parent={parentCoreData} {...{number, kind, court, main}} otherConditionsMet={(tree.holdingAmount > 50 || !!main) && !!tree.ustUnternehmer && !!tree.keineVerflechtungen}/>

    </>

}