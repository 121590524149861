import {Container} from "./core/components";
import React from "react";

const translate = {
    vertretung_bezeichnung: "Vertretungsregel",
    vertretung_code: "Vertretungsregel Code",
}

export function ShowEntityData({coreData}) {
    const sd = coreData?.stammdaten;
    return <Container style={{padding: 10}} name={coreData.stammdaten.name}>

        <table className={"mytable"}>
            <tr>
                <td>Name</td>
                <td>{sd.name}</td>
            </tr>
            <tr>
                <td>Adresse</td>
                <td>{sd.strasse} {sd.hausnummer}, {sd.postleitzahl} {sd.ort}, {sd.staat}</td>
            </tr>
            {
                Object.entries(coreData.stammdaten)
                    .filter(([k, v]) => !["name", "strasse", "hausnummer", "postleitzahl", "ort", "staat"].includes(k))
                    .map(([k, v]) => <tr>
                        <td>{translate[k] ?? (k[0].toUpperCase() + k.substring(1))}</td>
                        <td>{v}</td>
                    </tr>)
            }
        </table>

        <h3>Vertretungen</h3>
        {
            coreData?.beteiligungen && Object.entries(
                coreData?.beteiligungen.reduce((obj, curr) => ({...obj, [curr.rolle]: [...(obj[curr.rolle] || []), curr]}), {})
            )
                .filter(([code, beteiligungen]) => code !== "295")
                .map(([code, beteiligungen]) => <div>
                    <h3>{beteiligungen[0].rollenbezeichnung}</h3>
                    <ul>

                        {
                            beteiligungen.map(a => <li style={{fontWeight: a.befugnisse?.map(b => b.bezeichnung).includes("einzelvertretungsberechtigt") ? "bold" : undefined}}>
                                {
                                    !!a.vorname ? <>{a.vorname} {a.nachname}, {a.geburtsdatum}, {a.befugnisse?.map(b => b.bezeichnung).join(", ")}</> :
                                        <>{a.name}, {a.registernummer} {a.registerbezeichnung}, {a.befugnisse?.map(b => b.bezeichnung).join(", ")}</>
                                }
                            </li>)
                        }
                    </ul>
                </div>)
        }
    </Container>
}