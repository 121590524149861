import React, {memo, useMemo, useRef, useState} from "react";
import {createPortal} from "react-dom";
import JSONPretty from "react-json-pretty";
import {courts} from "./core/courts";

import "reactflow/dist/style.css";
import ReactFlow, {Controls, Handle, MarkerType, MiniMap, Position, ReactFlowProvider, useOnViewportChange, useViewport,} from "reactflow";

const ColorSelectorNode = memo(({data, isConnectable}) => {
    return (
        <>
            <Handle
                type="target"
                position={Position.Left}
                style={{background: '#555'}}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <div>
                Custom Color Picker Node: <strong>{data.color}</strong>
            </div>
            <input className="nodrag" type="color" onChange={data.onChange} defaultValue={data.color}/>
            <Handle
                type="source"
                position={Position.Right}
                id="a"
                style={{top: 10, background: '#555'}}
                isConnectable={isConnectable}
            />
            <Handle
                type="source"
                position={Position.Right}
                id="b"
                style={{bottom: 10, top: 'auto', background: '#555'}}
                isConnectable={isConnectable}
            />
        </>
    );
});
const initBgColor = "#1A192B";
const nodeTypes = {
    selectorNode: ColorSelectorNode
};
const IFrame = ({
                    children,
                    ...props
                }) => {
    const [contentRef, setContentRef] = useState(null)
    const mountNode =
        contentRef?.contentWindow?.document?.body

    return (
        <iframe {...props} ref={setContentRef}>
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    )
}

function getName({kind, number, court}) {
    const key = `hreintrag|${kind}|${number}|${court}`
    const local = localStorage.getItem(key)
    try {
        return JSON.parse(local)["stammdaten"]["name"]
    } catch (e) {

    }
}


function flatten(tree, level, index, rowCount, minLeft) {

    // needs to be after sub call
    rowCount[level + 1] = (rowCount[level + 1] ?? 0) + (tree.subs?.length || 0)

    let subs = [];
    let b = 1;

    tree.subs?.filter(a => a.hash).forEach((curr, i) => {//.reduce((obj, curr, i) => {
        const ss = flatten(curr, level + 1, i, rowCount, Math.max(rowCount[level + 2] ?? 0, minLeft + i))
        subs.push(...(ss.a || []))
        b = b + ss.b;
    });


    console.log(`${tree.kind} ${tree.number} ${courts[tree.court] || tree.court}`, rowCount, minLeft)
    return {
        a: [
            ...(tree.hash && tree.number ? [{
                id: tree.hash.toString(),
                data: {label: `${getName(tree)} ${tree.kind} ${tree.number} ${courts[tree.court] || tree.court}`},
                type: level === 1 ? "input" : (!(tree.subs?.length ?? 0) ? "output" : ""),
                connectable: false,
                level, index,
                numChilds: tree.subs?.length,
                position: {x: minLeft * 200, y: 200 * (level + 1)},
                targetPosition: "top",
            }] : []),
            ...(subs)
        ],
        b: b,
        rowCount,
    }
}

function edgeFilter(tree) {
    return [...(tree.hash && tree.subs?.map(s => ({
        id: tree.hash + "|" + s.hash, source: tree.hash.toString(), target: s.hash.toString(), label: `${s.holdingAmount} %`,
        markerEnd: {
            type: MarkerType.ArrowClosed
        },
        sourceHandle: 'a',
        type: 'straight',
    })) || []), ...(tree.subs?.reduce((obj, curr) => [...obj, ...edgeFilter(curr)], []) || [])]
}

export function Organigram(props) {
    return <ReactFlowProvider>
        <OrganigramCore {...props}/>
    </ReactFlowProvider>
}


export function OrganigramCore({tree}) {
    const [reactFlowInstance, setReactFlowInstance] = useState();
    const [viewPortChanged, setViewPortChanged] = useState(false);
    useOnViewportChange({
        onStart: () => setViewPortChanged(true)
    })
    const nodes = useMemo(() => {
        !viewPortChanged && window.setTimeout(() => reactFlowInstance?.fitView(), 100)
        const {a: nodes, b, rowCount} = flatten(tree, 0, 0, {}, 0)
        return nodes;
    }, [tree])
    const edges = useMemo(() => edgeFilter(tree), [tree])

    const [bgColor, setBgColor] = useState(initBgColor);

    const connectionLineStyle = {stroke: "#fff"};

    const defaultViewport = {x: 0, y: 0, zoom: 1.5};
    const rf = useRef();
    return <div style={{width: window.innerWidth-40, height: window.innerWidth/2, marginTop: 20}}>


        <ReactFlow
            onInit={setReactFlowInstance}
            nodes={nodes}
            edges={[...edges,
            ]}

            ref={rf}
            style={{background: bgColor}}
            connectionLineStyle={connectionLineStyle}
            defaultViewport={defaultViewport}
            fitView
        >
            <MiniMap
                nodeStrokeColor={(n) => {
                    if (n.type === "input") return "#0041d0";
                    if (n.type === "selectorNode") return bgColor;
                    if (n.type === "output") return "#ff0072";
                }}
                nodeColor={(n) => {
                    if (n.type === "selectorNode") return bgColor;
                    return "#fff";
                }}
            />
            <Controls/>
        </ReactFlow>
        {/*
        <JSONPretty json={nodes}/>
        <JSONPretty json={edges}/>
*/}
    </div>
}
