import './App.css';
import React, {createContext, useRef, useState} from "react";
import {downloadObjectAsJson} from "./core/download";
import {MyModal} from "./core/modal";
import {EntityView} from "./entityView";
import {Organigram} from "./organigram";
import GlobalContext from "./core/globalContext"
import JSONPretty from "react-json-pretty";

const testData2 = {
    "subs": [
        {
            "hash": 0.46829530833422406,
            "kind": "HRB",
            "number": "250107",
            "court": "D2601",
            "ustUnternehmer": true,
            "subs": [
                {
                    "hash": 0.7237329236982428,
                    "kind": "HRB",
                    "number": "76681",
                    "court": "D2601",
                    "holdingAmount": "100",
                    "ustUnternehmer": true,
                    "keineVerflechtungen": true,
                },
                {
                    "hash": 0.303846922681579,
                    "kind": "HRB",
                    "number": "158063",
                    "court": "D2601",
                    "holdingAmount": "81",
                    "ustUnternehmer": true,
                    "keineVerflechtungen": true,
                },
                {
                    "hash": 0.03452684539057971,
                    "kind": "HRB",
                    "number": "207200",
                    "court": "D2601",
                    "holdingAmount": "100",
                    "ustUnternehmer": true,
                    "keineVerflechtungen": true,
                }
            ]
        }
    ]
}

const testData = {
    "subs": [{
        "hash": 0.46829530833422406,
        "kind": "HRB",
        "number": "250107",
        "court": "D2601",
        "ustUnternehmer": true,
        "subs": [{"hash": 0.7237329236982428, "kind": "HRB", "number": "76681", "court": "D2601", "holdingAmount": "100", "ustUnternehmer": true, "keineVerflechtungen": true}, {
            "hash": 0.303846922681579,
            "kind": "HRB",
            "number": "158063",
            "court": "D2601",
            "holdingAmount": "81",
            "ustUnternehmer": true,
            "keineVerflechtungen": true,
            "subs": [{"hash": 0.001286059024999564, "kind": "HRB", "number": "89235", "court": "R3101", "holdingAmount": "121"}]
        }, {
            "hash": 0.03452684539057971,
            "kind": "HRB",
            "number": "207200",
            "court": "D2601",
            "holdingAmount": "100",
            "ustUnternehmer": true,
            "keineVerflechtungen": true,
            "subs": [{"hash": 0.7263990057967448, "kind": "HRB", "number": "123125", "court": "R3101", "holdingAmount": "100", "subs": [{"hash": 0.7137352251655417, "kind": "HRB", "number": "262523", "court": "Y1101", "holdingAmount": "222"}]}, {
                "hash": 0.9361365704810465,
                "kind": "HRB",
                "number": "326236",
                "court": "D3101",
                "holdingAmount": "222"
            }]
        }]
    }]
}
const testData3 = {
    "subs": [{
        "hash": 0.46829530833422406,
        "kind": "HRB",
        "number": "250107",
        "court": "D2601",
        "ustUnternehmer": true,
        "subs": [{
            "hash": 0.7237329236982428,
            "kind": "HRB",
            "number": "76681",
            "court": "D2601",
            "holdingAmount": "100",
            "ustUnternehmer": true,
            "keineVerflechtungen": true,
            "subs": [{"hash": 0.6451234469307988, "kind": "HRA", "number": "3413", "court": "D2910", "holdingAmount": "100", "ustUnternehmer": true, "keineVerflechtungen": true}]
        }, {
            "hash": 0.303846922681579,
            "kind": "HRB",
            "number": "158063",
            "court": "D2601",
            "holdingAmount": "81",
            "ustUnternehmer": true,
            "keineVerflechtungen": true,
            "subs": [{"hash": 0.001286059024999564, "kind": "HRB", "number": "89235", "court": "R3101", "holdingAmount": "121"}]
        }, {
            "hash": 0.03452684539057971,
            "kind": "HRB",
            "number": "207200",
            "court": "D2601",
            "holdingAmount": "100",
            "ustUnternehmer": true,
            "keineVerflechtungen": true,
            "subs": [{"hash": 0.7263990057967448, "kind": "HRB", "number": "123125", "court": "R3101", "holdingAmount": "100", "subs": [{"hash": 0.7137352251655417, "kind": "HRB", "number": "262523", "court": "Y1101", "holdingAmount": "222"}]}, {
                "hash": 0.9361365704810465,
                "kind": "HRB",
                "number": "326236",
                "court": "D3101",
                "holdingAmount": "222"
            }]
        }],
        "keineVerflechtungen": true
    }]
}


function App() {

    const fileRef = useRef(null)
    const [state, setState] = useState({});
    const [tree, setTree] = useState({
        "subs": [
            {
                "hash": 0.46829530833422406,
            }
        ]
    })
    const [reloadKey, setReloadKey] = useState(null);


    return <div className="App" style={{padding: 20}}>
        <GlobalContext.Provider value={{state, setState}}>


            <h1>USt-Organschaft Überprüfung</h1>

            <button onClick={() => downloadObjectAsJson(tree, "konfiguration.json")}>Konfiguration herunterladen</button>
            &nbsp;
            <button onClick={() => setTree(testData3)}> importieren test</button>
            &nbsp;

            <MyModal trigger={<button>Konfiguration importieren</button>}>
                {
                    close => <div style={{padding: 10}}>

                        <input type={"file"} ref={fileRef}/>
                        <button onClick={() => {
                            var reader = new FileReader();
                            reader.readAsText(fileRef.current.files[0], "UTF-8");
                            reader.onload = function (evt) {
                                const tmp = JSON.parse(evt.target.result);
                                setTree(tmp)
                                close()
                            }
                        }}>importieren
                        </button>
                    </div>
                }
            </MyModal>
            &nbsp;
            <button onClick={() => {
                const items = {...localStorage};
                console.log(items)
                //Object.keys(items).forEach((k) => localStorage.removeItem(k))
                setReloadKey(Math.random())
            }}>alle Entitäten (neu) laden
            </button>


            <EntityView tree={tree} setTree={setTree} main reloadKey={reloadKey}/>


            <Organigram tree={tree}/>
        </GlobalContext.Provider>
    </div>
}

export default App;
