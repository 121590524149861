import React, {useRef, useState} from "react";
import {courts} from "./core/courts";
import {ShowMainData} from "./showMainData";

export function EntityView({tree, setTree, main, reloadKey, parent, submain}) {
    const [loadData, setLoadData] = useState(false);
    const ref = useRef();

    const onChange = (name) => {
        return {
            value: tree[name] ?? "",
            onChange: e => {
                setTree(b => ({...b, [name]: e.target.type === "checkbox" ? e.target.checked : e.target.value}))
            },
            ref,
            checked: typeof tree[name] === "boolean" ? tree[name] : undefined,
        }
    }

    return <div key={tree.hash} style={main ? {} : {padding: 10, border: "1px solid orange", marginTop: 10}}>
        {
            !main && <>


                <ShowMainData {...{...tree, subs: undefined, loadData, reloadKey: (loadData??0)+(reloadKey??0), parent, tree, setTree, main: main || submain}}>
                    <label>
                        Art
                        <select {...onChange("kind")}>
                            <option></option>
                            <option>HRA</option>
                            <option>HRB</option>
                            <option>GnR</option>
                            <option>PR</option>
                            <option>VR</option>
                            <option>GsR</option>
                        </select>
                    </label>
                    &nbsp;
                    <label>
                        Nummer <input {...onChange("number")}/>
                    </label>
                    &nbsp;
                    <label>
                        Registergericht <select  {...onChange("court")}>
                        {Object.entries(courts).map(([k, v]) => <option value={k} key={k}>{v}</option>)}
                    </select>
                    </label>
                    &nbsp;
                    &nbsp;
                    <button onClick={() => setLoadData(Math.random())}>lade Entität</button>
                    &nbsp;
                    &nbsp;
                </ShowMainData>

            </>
        }


        { //(`${a.number}${a.court}${a.kind}`)
            tree.subs?.map((a, i) => <EntityView key={a.hash} tree={a} reloadKey={reloadKey} submain={main} parent={{...tree, subs: undefined}} setTree={b => setTree(c => {
                return ({
                    ...c, subs: [
                        ...(c.subs || []).slice(0, i),
                        b(c.subs[i]),
                        ...(c.subs || []).slice(i + 1)
                    ]
                })
            })}/>)
        }

        <button style={{marginTop: 6}} onClick={() => setTree(b => ({...b, subs: [...(b.subs || []), {hash: Math.random()}]}))}>Entität hinzufügen</button>

    </div>
}