
import React, {useState} from "react"
import {FaTimesCircle} from "react-icons/fa"
import {Dialog} from "@mui/material"
// @ts-ignore
import styles from "./Modal.module.sass"

export interface MyModalProps {
    trigger?: any
    defaultOpen?: boolean
    children: any
    hoverEnabled?: boolean
    alwaysOpen?:boolean
    onOpen?: Function
    onClose?: Function
    wide?: boolean
    additionalPaperStyles?: string
}

export const MyModalContext = React.createContext({isModal: false})

export const MyModal = ({trigger, defaultOpen = false, children, hoverEnabled = false, onOpen, alwaysOpen=false, onClose, wide = false, additionalPaperStyles = ""}: MyModalProps) => {
    const [open, setOpenRaw] = useState(!!defaultOpen)
    const setOpen = (open: boolean) => {
        if (open && onOpen) {
            onOpen()
        }
        setOpenRaw(open)
    }
    const close = (trigger: any) => () => {
        if (onClose) {
            const returnFromClose = onClose(trigger)
            if (returnFromClose !== false) {
                setOpen(false)
            }
        } else {
            setOpen(false)
        }
    }
    return <MyModalContext.Provider value={{isModal: true}}>
        {
            trigger && <i
                style={{cursor: "pointer"}}
                onClick={(e) => {
                    e.preventDefault()
                    setOpen(!open)
                    return false
                }}
                onMouseOver={() => hoverEnabled && setOpen(!open)}>
                {trigger}
            </i>
        }
        <Dialog
            open={alwaysOpen || open}
            onClose={close("eventHandler")}
            classes={{root: "MyDialogRoot", paper: additionalPaperStyles + ' ' + styles.MyDialogPaper + (wide ? " " + styles.WidePaper : ""), container: styles.MyMuiContainer}}
            maxWidth={"lg"}
        >
            <div className={styles.MyDialogCloseButton} onClick={close("closeButton")}><FaTimesCircle/></div>
                {typeof children === "function" ? children(close("child")) : children}
        </Dialog>
    </MyModalContext.Provider>
}
