import React from "react";
import "./status.module.sass"
import {FaWindowClose} from "react-icons/fa";
import {Loader} from "./components"

interface StatusProps {
    type: "error" |"success"| "successRegistration" |"info"
    text?: string | JSX.Element
    hideTitle?: boolean
    children?: any
}

interface StatusState {
    displayStyle: "block" | "none"
    textNotShowing: string
}

export default class Status extends React.Component<StatusProps, StatusState> {
    private ref: React.RefObject<any>;
    constructor(props: StatusProps) {
        super(props);
        this.state = {
            displayStyle: "block",
            textNotShowing: ""
        };
        this.ref = React.createRef();
    }

    typeTranslations = {
        error: "Fehler",
        success: "Erfolgreich",
        successRegistration: "Anmeldung erfolgreich",
        info: "Information",
    }

    componentDidUpdate(prevProps:Readonly<StatusProps>) {
        if (this.props.text !== prevProps.text) {
            this.setState({
                displayStyle: "block"
            })
        }
    }

    render() {
        if (this.props.text === "" || this.props.text === undefined) {
            return null
        }
        return <div id={"status_" + this.props.type} className={"status " + this.props.type}
                    style={{display: this.state.displayStyle}}>
            <FaWindowClose style={{float: "right"}} onClick={() => {
                this.setState({
                    displayStyle: "none",
                })
            }}/>
            {
                !this.props.hideTitle && <h2>
                    {this.typeTranslations[this.props.type] === undefined && this.props.type.substr(0, 1).toUpperCase() + this.props.type.substr(1)}

                    {this.typeTranslations[this.props.type] !== undefined && this.typeTranslations[this.props.type]}
                </h2>
            }
            <div>{this.props.text}{this.props.children}</div>
        </div>

    }
}


