import {isIE} from "react-device-detect"
import axios from "axios"

export function downloadObjectAsJson(exportObj: any, exportName: string) {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}

export function downloadAsJson(content: any, filename: string) {
    const data = new Blob([s2ab(atob(encodeURIComponent(content)))], {type: 'text/json; charset=utf-8'})
    const csvURL = window.URL.createObjectURL(data)
    let tempLink = document.createElement('a')
    tempLink.href = csvURL
    tempLink.setAttribute('download', filename)
    tempLink.click()
    //document.body.removeChild(tempLink);
}

export function downloadAsCsv(content: any, filename: string) {
    const data = new Blob([s2ab(atob(content))], {type: 'text/csv; charset=utf-8'})
    const csvURL = window.URL.createObjectURL(data)
    let tempLink = document.createElement('a')
    tempLink.href = csvURL
    tempLink.setAttribute('download', filename)
    tempLink.click()
    //document.body.removeChild(tempLink);
}

export function downloadTextAsCsv(content: any, filename: string) {
    var csvURL = encodeURI(content)

    let tempLink = document.createElement('a')
    tempLink.href = "data:text/csv;charset=utf8," + "\uFEFF" + csvURL
    tempLink.setAttribute('download', filename)
    tempLink.click()

    //document.body.removeChild(tempLink);
}

function utf8_to_str(a: any) {
    for (var i = 0, s = ''; i < a.length; i++) {
        var h = a[i].toString(16)
        if (h.length < 2) h = '0' + h
        s += '%' + h
    }
    return decodeURIComponent(s)
}

// Achtung: Blob-Management wie in Credit.tsx
export function downloadAsPdf(content: string, filename: string) {
    const data = new Blob([content], {type: 'application/pdf'})
    if (isIE) {
        // @ts-ignore
        window.navigator.msSaveOrOpenBlob && window.navigator.msSaveOrOpenBlob(data, filename)
    } else {
        const pdfURL = window.URL.createObjectURL(data)
        let tempLink = document.createElement('a')
        tempLink.href = pdfURL
        tempLink.setAttribute('download', filename)
        tempLink.click()
    }
}

export function downloadAsPdfWithUrl(url: string, filename: string) {
    axios.get(url, {responseType: "blob"}).then(
        (resp: any) => {
            downloadAsPdf(resp.data, filename)
        }
    )
}

function s2ab(s: any) {
    let buf = new ArrayBuffer(s.length)
    let view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
    return buf
}

