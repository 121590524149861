import React, {useContext, useMemo, useState} from "react";
import {MyModal} from "./core/modal";
import {FaInfoCircle} from "react-icons/fa";
import {G, R} from "./helpers";
import {Container} from "./core/components";
import GlobalContext from "./core/globalContext";
import {courts} from "./core/courts";

const gfOrVorstand = ["085", "086", "194", "294",];

function compareBeteiligung(a, b) {
    //const k = c => `${c.vorname}${c.nachname}${c.geburtsdatum}`;
    if (a.vorname === b.vorname && a.nachname === b.nachname && a.geburtsdatum === b.geburtsdatum && (a.geschlecht === 0 || b.geschlecht === 0 || a.geschlecht === b.geschlecht)) {
        return true
    }
    return false
}

export function Check({child, parent, number, kind, court, main, otherConditionsMet}) {
    const context = useContext(GlobalContext)
    const [overrideInvalid, setOverrideInvalid] = useState(false);
    let errors = "";
    let checked = true;
    let valid = false;
    let same = [], samegf = [], gf = []

    if (child?.stammdaten && parent?.stammdaten) {

        const same = child.beteiligungen.filter(b => {
            return parent.beteiligungen?.find(c => compareBeteiligung(b, c))
        }).filter(b => [...gfOrVorstand, "285",].includes(b.rolle))
        const samegf = child.beteiligungen.filter(b => {
            return parent.beteiligungen?.find(c => compareBeteiligung(b, c))
        }).filter(b => [...gfOrVorstand,].includes(b.rolle))

        const gf = child.beteiligungen.filter(b => [...gfOrVorstand,].includes(b.rolle))

        if (child.stammdaten.vertretung_code === "052") {
            valid = samegf.length > gf.length / 2
        } else if (["016", "051", "064",].includes(child.stammdaten.vertretung_code)) {
            valid = gf.length === samegf.length
        } else if (["027", "034", "065", "082"].includes(child.stammdaten.vertretung_code)) {
            valid = samegf.length >= Math.min(2, gf.length)
        } else if (["028", "035", "066", "083"].includes(child.stammdaten.vertretung_code)) {
            valid = samegf.length >= 1 && (same.length >= Math.min(2, gf.length))
        } else if (["089"].includes(child.stammdaten.vertretung_code)) {
            valid = true
            child.beteiligungen.forEach(curr => {
                if (!curr.registernummer) {
                    valid = false
                }
                const k = `${curr.registernummer.replace(" ", "|")}|${court.replace("Amtsgericht ", "")}`
                console.log(k, context.state[k], !context.state[k] || !context.state[k].valid)
                if (!context.state[k] || !context.state[k].valid) {
                    if (!context.state[k]) {
                        errors += (!!errors ? ", " : "") + `${curr.name || (curr.vorname + " " + curr.nachname)} wurde nicht in Organigram gefunden`
                    }else if (!context.state[k]?.valid) {
                        errors += (!!errors ? ", " : "") + `${curr.name || (curr.vorname + " " + curr.nachname)} ist nicht wirtschaftlich eingegliedert`
                    }else{
                        errors += (!!errors ? ", " : "") + `${curr.name || (curr.vorname + " " + curr.nachname)} anderer Fehler`

                    }
                    valid = false
                }
            })
        } else {
            checked = false
        }

        if (samegf.filter(b => b.befugnisse.some(c => c.code === "003")).length >= 1) {
            // einer der gleichen GF hat zumindest eine Einzelvertretungsberechtigung
            valid = true
        }
        if (child.beteiligungen
            .filter(b => !parent.beteiligungen?.find(c => compareBeteiligung(b, c)))
            .filter(b => b.befugnisse.some(c => c.code === "003")).length >= 1) {
            // einer der NICHT-gleichen GF hat eine Einzelvertretungsberechtigung
            valid = false
        }
    } else {
        checked = false
    }

    useMemo(() => {
        context.setState(old => ({...old, [`${kind}|${number}|${court}`]: {valid: (valid || overrideInvalid || main) , otherConditionsMet, checked}}))
    }, [child, parent, kind, overrideInvalid, main, otherConditionsMet]);

    if (!child?.stammdaten || !parent?.stammdaten) {
        return null
    }

    if (checked) {
        return <p style={{margin: "5px 0", backgroundColor: valid || overrideInvalid ? "rgba(0,255,0,0.2)" : "rgba(255,0,0,0.2)", padding: 6}}>
            Durchsetzbarkeit: überprüft: ja, gültig: {valid ? <G>ja</G> : <R>nein</R>}, gleiche Gesellschafter: {same.map(b => b.vorname + " " + b.nachname).join(", ")} {same.length === 0 && <R>keine</R>}, {errors}
            <br/>
            {
                !valid && <label>
                    Es gibt sonstige Maßnahmen? <MyModal trigger={<FaInfoCircle>i</FaInfoCircle>}>
                    <div style={{padding: 20}}>
                        z.B. Beherrschungsverträge
                    </div>
                </MyModal>

                    &nbsp; <input type={"checkbox"} checked={overrideInvalid} onClick={() => setOverrideInvalid(o => !o)}/>
                </label>
            }
        </p>
    }


    return <p style={{margin: "5px 0", backgroundColor: "rgba(255,166,0,0.2)", padding: 10}}>Dieser Fall kann leider noch nicht überprüft werden. Die Vertretungsregelung lautet:<br/>{child.stammdaten.vertretung_bezeichnung}</p>
}