export const courts = {
    "|all": "",
    "R3101": "Aachen",
    "Y1201": "Altenburg",
    "D3101": "Amberg",
    "D3201": "Ansbach",
    "Y1101": "Apolda",
    "R1901": "Arnsberg",
    "Y1102": "Arnstadt",
    "Y1303": "Arnstadt Zweigstelle Ilmenau",
    "D4102": "Aschaffenburg",
    "D2102": "Augsburg",
    "P3101": "Aurich",
    "M1305": "Bad Hersfeld",
    "M1202": "Bad Homburg v.d.H.",
    "T2101": "Bad Kreuznach",
    "R2108": "Bad Oeynhausen",
    "Y1301": "Bad Salzungen",
    "D4201": "Bamberg",
    "D4301": "Bayreuth",
    "F1103": "Berlin (Charlottenburg)",
    "R2101": "Bielefeld",
    "R2201": "Bochum",
    "R3201": "Bonn",
    "P1103": "Braunschweig",
    "H1101": "Bremen",
    "U1206": "Chemnitz",
    "D4401": "Coburg",
    "R2707": "Coesfeld",
    "G1103": "Cottbus",
    "M1103": "Darmstadt",
    "D2201": "Deggendorf",
    "R2402": "Dortmund",
    "U1104": "Dresden",
    "R1202": "Duisburg",
    "R3103": "Düren",
    "R1101": "Düsseldorf",
    "Y1105": "Eisenach",
    "Y1106": "Erfurt",
    "M1602": "Eschwege",
    "R2503": "Essen",
    "X1112": "Flensburg",
    "M1201": "Frankfurt am Main",
    "G1207": "Frankfurt/Oder",
    "B1204": "Freiburg",
    "M1405": "Friedberg",
    "M1603": "Fritzlar",
    "M1301": "Fulda",
    "D3304": "Fürth",
    "R2507": "Gelsenkirchen",
    "Y1203": "Gera",
    "M1406": "Gießen",
    "Y1108": "Gotha",
    "P2204": "Göttingen",
    "Y1205": "Greiz",
    "R2103": "Gütersloh",
    "R2602": "Hagen",
    "K1101": "Hamburg",
    "R2404": "Hamm",
    "M1502": "Hanau",
    "P2305": "Hannover",
    "Y1109": "Heilbad Heiligenstadt",
    "Y1302": "Hildburghausen",
    "P2408": "Hildesheim",
    "D4501": "Hof",
    "V1102": "Homburg",
    "D5701": "Ingolstadt",
    "R2604": "Iserlohn",
    "Y1206": "Jena",
    "T3201": "Kaiserslautern",
    "M1607": "Kassel",
    "D2304": "Kempten (Allgäu)",
    "X1517": "Kiel",
    "R1304": "Kleve",
    "T2210": "Koblenz",
    "R3306": "Köln",
    "M1203": "Königstein",
    "M1608": "Korbach",
    "R1402": "Krefeld",
    "T3304": "Landau",
    "D2404": "Landshut",
    "R1105": "Langenfeld",
    "V1103": "Lebach",
    "U1308": "Leipzig",
    "R2307": "Lemgo",
    "M1706": "Limburg",
    "X1721": "Lübeck",
    "T3104": "Ludwigshafen a.Rhein (Ludwigshafen)",
    "P2507": "Lüneburg",
    "T2304": "Mainz",
    "B1601": "Mannheim",
    "M1809": "Marburg",
    "Y1304": "Meiningen",
    "D2505": "Memmingen",
    "V1104": "Merzig",
    "R1504": "Mönchengladbach",
    "T2214": "Montabaur",
    "Y1110": "Mühlhausen",
    "D2601": "München",
    "R2713": "Münster",
    "N1105": "Neubrandenburg",
    "V1105": "Neunkirchen",
    "G1309": "Neuruppin",
    "R1102": "Neuss",
    "Y1111": "Nordhausen",
    "D3310": "Nürnberg",
    "M1114": "Offenbach am Main",
    "P3210": "Oldenburg (Oldenburg)",
    "P3313": "Osnabrück",
    "V1107": "Ottweiler",
    "R2809": "Paderborn",
    "D2803": "Passau",
    "X1321": "Pinneberg",
    "Y1209": "Pößneck",
    "Y1208": "Pößneck Zweigstelle Bad Lobenstein",
    "G1312": "Potsdam",
    "R2204": "Recklinghausen",
    "D3410": "Regensburg",
    "N1206": "Rostock",
    "Y1210": "Rudolstadt",
    "V1109": "Saarbrücken",
    "V1110": "Saarlouis",
    "D4608": "Schweinfurt",
    "N1308": "Schwerin",
    "R3208": "Siegburg",
    "R2909": "Siegen",
    "Y1112": "Sömmerda",
    "Y1113": "Sondershausen",
    "Y1307": "Sonneberg",
    "P2106": "Stadthagen",
    "Y1214": "Stadtroda",
    "R2706": "Steinfurt",
    "W1215": "Stendal",
    "V1111": "St. Ingbert (St Ingbert)",
    "N1209": "Stralsund",
    "D3413": "Straubing",
    "B2609": "Stuttgart",
    "V1112": "St. Wendel (St Wendel)",
    "Y1308": "Suhl",
    "P2613": "Tostedt",
    "D2910": "Traunstein",
    "B2805": "Ulm",
    "V1115": "Völklingen",
    "P2716": "Walsrode",
    "D3508": "Weiden i. d. OPf.",
    "Y1114": "Weimar",
    "M1710": "Wetzlar",
    "M1906": "Wiesbaden",
    "T2408": "Wittlich",
    "R1608": "Wuppertal",
    "D4708": "Würzburg",
    "T3403": "Zweibrücken"
}