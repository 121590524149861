import React, {CSSProperties, MouseEventHandler, useState} from "react"
import {FaAngleDown, FaAngleRight, FaExclamationCircle, FaInfoCircle} from "react-icons/fa"

import {MyModal} from "./modal"

type _ContainerProps = {
    ID?: string,
    name?: string,
    addName?: JSX.Element,
    style?: any,
    // true - default collapsed, false - default open, undefined - not collapsable at all
    collapsable?: boolean,
    onCollapseChange?: (value: boolean) => any,
    hideHeading?: boolean,
    broad?: boolean,
    className?: string,
    center?: boolean
    visible?: boolean
    children?: any
}
const makeIdFromHeading = (name: string) => name.toLowerCase().replace(/\s/g, "_")


const Container = (props: _ContainerProps) => {
    const {ID, name, addName, style, hideHeading = false, broad = false, children, className, visible = true, center = false, collapsable, onCollapseChange} = props
    const [localCollapsed, setLocalCollapsed] = useState(collapsable)

    const onToggleCollapse = () => {
        if(collapsable === undefined) {
            return
        }

        setLocalCollapsed(s => {
            onCollapseChange && onCollapseChange(!s)
            return !s
        })
    }

    return (
        <div id={ID ? ID : (name !== undefined && name !== "" ? makeIdFromHeading(name) : "")}
             className={"reactContainer centercontent " + (broad ? "broad " : "") + (center ? " center " : "") + className}
             style={style}
        >
            {name !== undefined && name !== "" && !hideHeading &&
                <h2 onClick={() => onToggleCollapse()}>
                    {collapsable !== undefined && (localCollapsed ? <FaAngleRight /> : <FaAngleDown />)}
                    {name}
                    {addName}
                </h2>
            }
            { (collapsable === undefined || !localCollapsed) && children}
            <br/>
        </div>
    )
}

const LightContainer = (props: _ContainerProps) => {
    const {name, addName, collapsable = false, hideHeading = false, broad = false, children, className, visible = true, center = false, onCollapseChange} = props
    const [localCollapsed, setLocalCollapsed] = useState(collapsable)

    const onToggleCollapse = () => {
        if(collapsable === undefined) {
            return
        }

        setLocalCollapsed(s => {
            onCollapseChange && onCollapseChange(!s)
            return !s
        })
    }

    return (
        <>
            {name !== undefined && name !== "" && !hideHeading &&
                <h2 onClick={() => onToggleCollapse()}>
                    {collapsable !== undefined && (localCollapsed ? <FaAngleRight /> : <FaAngleDown />)}
                    {name}
                    {addName}
                </h2>
            }
            {children}
            <br/>
        </>
    )
}


type _SwitchProps = {
    children: any,
    text: string,
    defaultShow?: boolean
}

function MySwitch({children, text, defaultShow = false}: _SwitchProps) {
    const [noteWanted, setNoteWanted] = useState(defaultShow)

    if (noteWanted) {
        return children
    }
    return <em onClick={() => setNoteWanted(true)}>{text}</em>
}

type _CheckBoxProps = {
    tag: string,
    name: string,
    noLabel?: boolean,
    labelWidth?: number,

}
/*
work in progress
function CheckboxInput({tag, name, noLabel, labelWidth}: _CheckBoxProps) {
    const state = useContext(FormContext);
    let checked: string;
    const tagSplit = tag.split("_");
    if (tag.length === 2) {
        checked = state[tagSplit[0]] !== undefined && state[tagSplit[0]][tag[1]] !== undefined && state[tagSplit[0]][tag[1]] ? "checked" : ""
    } else {
        checked = state[tagSplit[0]] !== undefined && state[tagSplit[0]] !== undefined && state[tagSplit[0]] ? "checked" : ""
    }
    return <label className={"switchWrapper"}>
        {noLabel === undefined &&
        <span style={!!labelWidth ? {width: labelWidth.toString() + "px"} : {}}>
                    {name}
            </span>
        }
        <label className={"switch"}>
            <input type="checkbox" name={tag} checked={checked}/>
            <span className="slider"/>
        </label>
    </label>
}
*/
type _DefaultChildrenProp = {
    children: any,
    name?: string,
}

function InfoTooltip({children}: _DefaultChildrenProp) {
    return <MyModal trigger={<FaInfoCircle/>}>
        <div>
            {children}
        </div>
    </MyModal>
}

export function ErrorTooltip({children}: _DefaultChildrenProp) {
    return <MyModal trigger={<FaExclamationCircle style={{color: "#b00"}}/>}>
        <div>
            {children}
        </div>
    </MyModal>
}

type _LoadingProps = {
    loading?: boolean,
    style?: any,
}
const Loader = ({loading, style}: _LoadingProps) => loading ?
    <img alt={"loader"} className={"loader"} style={style} src="https://uid-suche.eu/src/img/ajax-loader-fff-big.gif" width={20} height={20}/> : null

export const AlwaysLoader = () => <Loader loading={true}/>

type _MaxBtnProps = {
    style?: CSSProperties,
    children: any,
    className?: string,
    onClick?: any,
    disabled?: boolean
    preventDefault?: boolean
}
const MaxBtn = ({style, children, className, onClick, preventDefault, ...props}: _MaxBtnProps) => <button style={style} onClick={(e) => {
    if(preventDefault) {
        e.preventDefault()
    }
    onClick && onClick(e)
}
} className={"maxbtn " + (className || "")} {...props}>{children}</button>
const MiniBtn = ({style, children, className, onClick, ...props}: _MaxBtnProps) => <MaxBtn style={style} onClick={onClick} className={"mini " + (className || "")} {...props}>{children}</MaxBtn>

export function TopButtonDiv({children}: { children: any }) {
    return <div className={"topButtonDiv"}>
        {children}
    </div>
}

type _MaxLinkProps = {
    to: string,
    style?: any,
    children: any,
    className?: string,
    onClick?: any,
}
const MaxLink = ({
                     to, style = {}, children, className = "", onClick = () => {
    }
                 }: _MaxLinkProps) => <a href={to} style={style} onClick={onClick} className={"maxbtn " + (className || "")}>{children}</a>

const BlankLink = ({children, name}: _DefaultChildrenProp) => <a href={(children.includes("http") ? "" : "http://") + children} target={"_blank"}>{name || children}</a>


export function Collapse({name, children, initialCollapsed, stopPropagation}: { name: string | JSX.Element, children: any, initialCollapsed: boolean, stopPropagation?: boolean }) {
    const [collapsed, setCollapsed] = useState(initialCollapsed)

    const onChange: MouseEventHandler<HTMLElement> = (e) => {
        stopPropagation && e.stopPropagation()
        setCollapsed(c => !c)
    }

    return <div>
        <i onClick={onChange} style={{cursor: "pointer"}}>{collapsed ? <FaAngleRight/> : <FaAngleDown/>} {name}</i>
        {
            !collapsed && children
        }
    </div>
}


export {Container, LightContainer, InfoTooltip, MySwitch, Loader, MaxBtn, MiniBtn, BlankLink, MaxLink}

