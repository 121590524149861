import DOMPurify from "dompurify"
import React, {DependencyList, Dispatch, EffectCallback, SetStateAction, useEffect, useState} from "react"


export const countriesConverter = require("i18n-iso-countries")
countriesConverter.registerLocale(require("i18n-iso-countries/langs/de.json"))

export const embrace = (t: number) => t > 0 ? `(${t})` : ""
//const t0 = new Date() - (new Date().getTime() % (3600 * 1000));

export const encodeGetParams = (p: Record<string, any>) => Object.entries(p || {}).filter(([, v]) => v !== undefined).map(kv => kv.map(encodeURIComponent).join("=")).join("&")

export const useMaxmo = (effect: Function, deps?: DependencyList) => useEffect(() => {
    effect()
}, deps)


export const pick = (records: Record<string, any>, pickables: string[]): Record<string, any> => {
    return pickables
        .map(p => (records[p] ? {key: p, value: records[p]} : null))
        .filter(p => !!p)
        .reduce((prev, current) => (current?.key ? {...prev, [current.key]: current?.value} : prev), {})
}

export function AddressPrinter({street = "", streetNumber = "", postcode = 0, city = "", country = "",}: { street?: string, streetNumber?: string, postcode?: number, city?: string, country?: string }) {
    const separator1 = (!!street || !!streetNumber) ? ", " : ""
    const separator2 = postcode !== 0 || !!city ? ", " : ""
    return `${street} ${streetNumber}${separator1}${postcode !== 0 ? postcode : ""} ${city}${country !== "AT" && country !== "" ? separator2 + countriesConverter.getName("AUT", "de") : ""} `
}

export function isJson(str: string) {
    try {
        JSON.parse(str)
        if (typeof (str) == 'string')
            if (str.length == 0)
                return false
    } catch (e) {
        return false
    }
    return true
}

const htmlDecode = (input: string) => {
    const doc = new DOMParser().parseFromString(input, "text/html")
    console.log(input)
    console.log(doc)
    return doc.documentElement.textContent
}

interface Partial {
    var: any
    placeholder: any
}

export function renderHtmlWithPlaceholders(html: string, {...props}, forceDiv = true, partials: Partial[] = []) {
    html = html?.replaceAll("&nbsp;", " ")?.replaceAll("&gt;", ">")

    let rendered
    try {
        const Handlebars = require("handlebars")
        Handlebars.registerHelper('logic', function (v1: any, operator: string, v2: any, options: any) {
            //console.log(v1, operator, v2)
            switch (operator) {
                case '==':
                    return (v1 == v2)
                case '===':
                    return (v1 === v2)
                case '!=':
                    return v1 != v2
                case '!==':
                    return (v1 !== v2)
                case '<':
                case "&lt;":
                    return (v1 < v2)
                case '<=':
                case '&lt;=':
                    return (v1 <= v2)
                case '>':
                case '&gt;':
                    return (v1 > v2)
                case '>=':
                case '&gt;=':
                    return (v1 >= v2)
                case '&&':
                case 'and':
                    return (v1 && v2)
                case '||':
                case 'or':
                    return (v1 || v2)
                case 'in':
                    return !!v2 && v1 in v2
                default:
                    return false
            }
        })
        Handlebars.registerHelper('in', function (v: any, ...options: any) {
            // Last option entry: Normal options object
            return v in options
        })
        Handlebars.registerHelper('raw', (options: any) => {
            // @ts-ignore
            return options.fn(this)
        })
        for (const y in (partials || [])) {
            const x = y as unknown as Partial
            if (!!x.var && !!x.placeholder) {
                Handlebars.registerPartial(x.var, x.placeholder)
            }
        }
        const template = Handlebars.compile(html || "")
        rendered = template({...props})
    } catch (e) {
        console.log(e)
        rendered = "<h3 style='color: red'>Ein Fehler beim Zuordnen der Daten ist aufgetreten</h3>" + html
    }
    //console.log(rendered, props)
    return forceDiv ? <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(`<div>${rendered}</div>`)}}/> : rendered //ReactDOMServer.renderToStaticMarkup(DOMPurify.sanitize(`<div>${rendered}</div>`))
}

export function useMyState<T = any>(data: T): { state: T, setState: Dispatch<SetStateAction<T>>, updateState: (a: T | ((b: T) => T)) => void } {
    const [state, setState] = useState<T>(data)
    const updateState = (a: T | ((b: T) => T)) => {
        typeof a === "function" ? setState(a) : setState((b: T) => ({...b, ...a}))
    }
    return {state, setState, updateState}
}

export function objGetEntries<T extends object>(obj?: T, keys?: (keyof T)[]): Array<{ key: keyof T, value: T[keyof T] }> {
    if (!obj) {
        return []
    }

    let ret: Array<{ key: keyof T, value: T[keyof T] }> = []
    // Object.keys() returns `string[]` but we will assert as `Array<keyof T>`
    for (const key of Object.keys(obj) as Array<keyof T>) {
        if (!keys || keys?.includes(key)) {
            ret = [...ret, {key: key, value: obj[key]}]
        }
    }
    return ret
}

export function take<S extends object, T extends keyof S>(object: S, members: T[]) {
    return members.reduce((prev, current) => {
        if (!object[current]) {
            return prev
        }
        return {...prev, [current]: object[current]}
    }, {} as Record<T, any>)
}

export function getSubProps(obj: any, path: string) {
    const splitted = path.split(".")
    let result = obj
    splitted.forEach(name => {
        if (!result) {
            return
        }
        result = result[name]
    })
    return result
}

declare global {
    interface Array<T> {
        joinComp(o: string | JSX.Element): Array<T>;
    }
}

Array.prototype.joinComp = function (clue) {
    return this.map((a, i) => <>{i > 0 ? clue : ""} {a}</>)
}


