

type StringEnum = {
    [index in string | number]: any;
}
export interface User {
    ID: number
    email: string
    prename: string
    lastname: string
    fullname: string
    phone: number
    role: number
    verein_ID: number
    verwalter: boolean
    country?: 'AT' | 'DE' | 'CH' | string
    data?: Record<string, string>
    isLoggedIn: boolean
    isAuthor: boolean
    isAdmin: boolean
}
interface moneyMaxCalcProps {
    time?: number
    s: any,
    currency?: number,
    club?: any
    addTax: boolean
    user: User
}

const fieldTranslation = {
    prename: "Vorname",
    lastname: "Nachname",
    phone: "Telefon",
    email: "E-Mail",
    pricenm: "Preis (kein Mitglied)",
    price: "Preis",
    olderthan: "Älter als",
    youngerthan: "Jünger als",
    street: "Straße",
    street_number: "Hausnummer",
    street_number_add: "Hausnummern-Zusatz",
    city: "Stadt",
    postal_code: "PLZ",
    postalCode: "PLZ",
    route: "Straße",
    locality: "Ort",
    birthdate: "Geburtsdatum",
    deathdate: "Sterbedatum",
    member: "Mitglied",
    memberStatus: "Mitglieds-Status",
    memberStatusNew: "Mitglieds-Status",
    memberStatusDescription: "Positionsbezeichnung",
    bank: "Banküberweisung", card: "Kreditkartenzahlung", sofort: "Sofortüberweisung", brig: "Bankbuchung", sepa_debit: "SEPA Lastschrift Einzug", voucher: "Gutschein", sepm_debit: "SEPA Lastschrift Einzug",
    titlesAnte: "Titel vorangestellt",
    titlesPost: "Titel nachgestellt",
    titlesJob: "Berufsbezeichnung",
    svn: "Sozialvers.-Nr.",
    letterAddressation: "Briefanrede",
    companyName: "Firmenname",
    companyPosition: "Position in Firma",
    vatID: "UID-Nummer",
    companyRegNr: "FB-Nummer",
    phoneAdd: "Telefon 2",
    source: "Erfassungs-Quelle",
    sex: "Geschlecht",
    IDLegacy: "vorherige ID",
    zvrNr: "ZVR-Nr",
    name: "Name",
    gender: "Geschlecht",
    minparts: "Minimale Teilnehmer",
    maxparts: "Maximale Teilnehmer",
    priceInput: "Preis (€)",
    pricenmInput: "Preis kein Mitglied (€)",
    bookingStart: "Buchbar ab",
    bookingEnd: "Buchbar bis",
    dueDate: "Fällig am",
    active: "Aktiv",
    allBookingStart: "Für neue Mitglieder buchbar ab",
    showStart: "Anzeigen ab",
    showEnd: "Anzeigen bis",
    yearAllowedLeft: "Jahrgänge ab inklusive (JJJJ)",
    yearAllowedRight: "Jahrgänge bis inklusive (JJJJ)",
    membersOnly: "Mitgliedschaft erforderlich?",
    audience: "Zielgruppe",
    rolesAllowed: "Definierende Rollen",
    tags: "Tags",
    description: "Beschreibung",
    information: "Information",
    trainerBecause: "Trainer weil",
    trainerSince: "Trainer seit",
    iLike: "Der Turnverein gefällt mir",
    titleDetail: "Detailierter Titel",
    titleDisplay: "Angezeigter Titel",
    institute: "Ausbildungsinstitut",
    location: "Ausbildungsort",
    file: "Datei",
    shortName: "Kurzname",
    showLogo: "Zeige Logo beim Login",
    showDiscounts: "Ermögliche die Eingabe von Rabattcodes",
    iban: "IBAN",
    bic: "BIC",
    bankname: "Name der Bank",
    dateFounded: "Gründungsdatum",
    sendReminders: "System-E-Mails senden",
    sendPaymentReminders: "Zahlungserinnerungen senden",
    paymentDueDate: "Fällig ab",
    paymentMethods: "Bezahlmethoden",
    memberStates: "Mitgliedsstatuscodes",
    enableOnlineConferences: "Online Treffen",
    trainersAllowed: "erweiterter Trainerzugriff",
    enableMedia: "Medienabschnitt",
    downloadCertificate: "Zertifikat herunterladen",
    downloadCertificateID: "Standard-Zertifikatsvorlage",

    type: "Typ",
    label: "Bezeichnung",
    tag: "Tag",
    showIfNonExistent: "Zeige immer an",
    editable: "Bearbeitungsmodus",
    required: "Erforderlich",
    showInAMS: "Zeige in Mitgliedskartenscan-Kiosk",
    additionalFields: "Zusätzliche Felder",
    props: "Weitere Daten",

    noMembership: "Keine Mitgliedschaft im Verein",
    boardMembershipFeeMode: "Mitgliedsbeitr. fällig für",
    roomBookingViewMode: "Raumbuchungsmodus",
    showMembershipCard: "Zeige Mitgliedskarte",
    showMediaMode: "Lege die Sichbarkeit von Medien fest",
    svnRequired: "Sozialversicherungsnummer benötigt",
    hideCapacity: "Anzahl freier Plätze von Kursen ausblenden",
    registerAusserordentlichesMitgliedText: "Information für die Anmeldung außerordentlicher Mitglieder",
    registerOrdentlichesMitgliedText: "Information für die Anmeldung ordentlicher Mitglieder",
    registerUnterstuetzendesMitgliedText: "Information für die Anmeldung unterstützender Mitglieder",
    paymentReminderInterval: "Intervall der Zahlungserinnerungen (in Tagen)",

    color: "Hintergrundfarbe",
    fontColor: "Textfarbe",
    fontColorInverse: "Invertierte Textfarbe",

    dataprotectionLink: "Link zur Datenschutzerklärung",
    agbsLink: "Link zu den AGBs",
    statutenLink: "Link zu den Statuten",
    facebook: "Link der Neuigkeiten-Seite",

    invoiceDate: "Rechnungsdatum",
    invoiceNumber: "Rechnungsnummer",
    comment: "Kommentar",
    contact: "Kontakt",
    contactID: "Kontakt",
    defaultInvoiceTemplateID: "Standard Rechnungsvorlage",
    invoiceTemplateID: "Rechnungvorlage",
    newsletterTemplateID: "Newsletter-Vorlage",

    showTax: "Steuer ausweisen",
    showAmount: "Menge anzeigen",
    sendInvoicePDFs: "Sende Rechnung bei Kursbuchung",
    invoice_rows: "Rechnungszeilen",

    WaitlistConfirmationText: "Text von E-Mails bei Übernahme von Wartelisten",
    CourseConfirmationText: "Text bei Kursbuchungsbestätigungen",
    PaymentReminderText: "Text für Zahlungserinnerungen",
    EmailGreetings: "E-Mail Grußzeile",
    EmailSignature: "E-Mail Signatur",
    childID: "Personennummer",
    course_parts_ID: "Kursteilnahme-Nummer",
    amount: "Menge",
    taxRate: "Steuersatz",
    paymentStatus: "Zahlungsstatus",
    invoice_ID: "Rechnungsnummer",
    additionalText: "Zusätzlicher Text",
    coursePaymentModel_ID: "Nummer der Zahlungsweise",
    Entry_ID: "Eintrags-Nummer",
    Group_ID: "Gruppen-Nummer",
};

class ConstsClass {
    placeHolders: Record<string, string | number> = {
        svn: "1234010101",
        email: "max@mustermann.at",
        prename: "Max",
        lastname: "Mustermann",
        phone: "+43 664 1234567",
        companyName: "Muster GmbH",
        vatID: "ATU12345678",
        companyRegNr: "123456a",
        name: "Allgemeiner Musterverein",
        website: "www.musterverein.at",
        zvrNr: 12456789,
        iban: "AT12 3456 7890 1234 5678"
    };
    weekdayNames = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];


    /*memberStates = {
        0: "Nichtmitglied",
        1: "ordentliches Mitglied",
        2: "außerordentliches Mitglied",
        3: "unterstützendes Mitglied",

        4: "befreites Mitglied",
        5: "Zeugwart Stellvertretung",
        6: "Zeugwart",

        7: "Sportliche Leitung Stellvertretung",
        8: "Sportliche Leitung",
        9: "Kassier Stellvertretung",
        10: "Kassier",
        11: "Schriftführer Stellvertretung",
        12: "Schriftführer",
        13: "Obfrau/Obmann Stellvertretung",
        14: "Obfrau/Obmann",

        40: "ruhendes Mitglied",
        41: "Trainer",

        //100: "Vorstand"
    };
    memberStatesShort = Object.keys(this.memberStates).reduce((obj, k) => ({...obj, [k]: this.memberStates[k].split(" ").map(u => u[0]).join(".")}), {});*/
    kontenKinds = {0: "Allgemeines Konto", 1: "Bank", 5: "Kundenkonto", /*2: "Kontenklasse", 3: "Kostenträger"*/};
    buchSymbole = {
        AR: "Ausgangsrechnung", ER: "Eingangsrechnung", BB: "Bilanzbuchung", BU: "Buchungsanweisung", EB: "Eröffnungsbilanz",
        KA: "Kassa", SO: "Sonstige Belege", BK: "Bank", LG: "Lohn/Gehalt", UE: "Saldenübernahme", GU: "Ausgangsgutschrift", EG: "Eingangsgutschrift"
    };
    sexMap = {male: "männlich", fem: "weiblich"};
    translate = (name: keyof typeof fieldTranslation | any) => !!name ? fieldTranslation[name as keyof typeof fieldTranslation] || ((name[0] || "").toUpperCase() + name.substr(1)) : "";


    money = new Intl.NumberFormat('de-AT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    });
    moneyAlt = new Intl.NumberFormat('de-AT', {
        style: 'currency',
        currency: 'ATS',
        minimumFractionDigits: 2
    });
    PHASE: string;
    API_PREFIX: string;
    user_id_prod: any;


    constructor() {
        this.PHASE = process.env.NODE_ENV || "";
        this.f = this.f.bind(this);
        let f = this.f;

        this.API_PREFIX = f("/api", "/api");
    }

    f<T = any>(dev: T, prod: T, user_id = -1) {
        if (process.env.NODE_ENV === "development" || (user_id !== -1 && this.user_id_prod.indexOf(user_id) < 0)) {
            return dev
        }
        return prod
    }

    moneyMaxCalc = ({s, currency = 0, club, addTax = false, time = 0, user}: moneyMaxCalcProps) => {
        const nowVatRegistered = !!club?.vatRegisteredSince && (club?.vatRegisteredSince < (time || (new Date().getTime() / 1000)))
        let taxRate = null;
        if (nowVatRegistered && addTax) {
            if (!!user && (!Consts.EUCountries.includes(user?.country?.toUpperCase() || "AT") || (user?.country !== "AT" && ((user?.data || {})["USt-Id-Nr"] || "") != ""))) {
                taxRate = 0
            } else {
                s = s * 1.2;
                taxRate = 20
            }
        }
        return this.moneyMax(s, currency, taxRate, club, time)
    };

    moneyMax = (s: any, currency = 0, taxRate: number | null = null, club?: any, time: number = 0) => {
        const nowVatRegistered = !!club?.vatRegisteredSince && (club?.vatRegisteredSince < (time || (new Date().getTime() / 1000)))
        return (currency === 0 ? this.money.format(s).replace(".", " ") : this.moneyAlt.format(s).replace(".", " ")) + (taxRate !== null && taxRate !== undefined && nowVatRegistered && s != 0 ? " (inkl. " + taxRate + "% USt" + (taxRate === 0 ? ", Reverse Charge)" : ")") : "")
    };

    telCommonCountryCodes = [
        43,
        39,
        32,
        371,
        387,
        382,
        385,
        212,
        420,
        31,
        45,
        47,
        372,
        48,
        358,
        351,
        33,
        7,
        49,
        421,
        350,
        386,
        44,
        34,
        30,
        46,
        36,
        41,
        353,
        90,
    ];

    isNumber = function (value: any) {
        return typeof value === 'number' &&
            isFinite(value) &&
            Math.floor(value) === value;
    };

    phoneNormalise(s: any) {
        if (this.isNumber(s) || !s)
            return s;

        s = s.replaceAll(" ", "");
        if (s === "")
            return 0;
        s = s.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");

        if (s.length < 5)
            return parseInt(s);

        if (s[0] === "+")
            s = s.substr(1);

        s = s.replace(/^0+/, "");

        if (!(this.telCommonCountryCodes.indexOf(parseInt(s.substr(0, 2))) > -1 || this.telCommonCountryCodes.indexOf(parseInt(s.substr(0, 3))) > -1)) {
            s = "43 " + s;
        } else {
            s = s.substr(0, 2) + " " + s.substr(2)
        }
        const cell = parseInt(s.substr(3, 3));
        let dist = 7;
        if (650 <= cell && cell <= 699) {
            dist = 6;
        }
        return "+" + s.substr(0, dist) + " " + s.substr(dist)
    }

    countries = [
        "AT", "DE",
        ...[
            "CH", "IT",
            "FR", "GB", "BE", "NL", "IE", "PL", "ES", "FI", "CZ", "SE", "NO", "HU", "SI", "SK", "SG", "DK", "EE", "LV", "LT", "MT", "PT", "GR", "RO", "BG", "HR",
            "CY", "LU", "LI"
        ].sort()
    ];

    paymentMethodsMap = {
        sepa: "SEPA Lastschrift",
        sepm: "SEPA Lastschrift",
        card: "Kreditkarte",
        sofort: 'Sofortüberweisung',
        bank: 'Banküberweisung',
        voucher: "Gutschein",
    }
    attendanceExprMap: StringEnum = {
        "0": "abwesend (auto)",
        "1": "anwesend",
        "2": "abwesend (man)",
        "3": "entschuldigt",
    };
    numOfAttendanceStatuses = 4;

    EUCountries = [
        "AT",
        "BE",
        "BG",
        "CY",
        "CZ",
        "DK",
        "EE",
        "FI",
        "HR",
        "FR",
        "DE",
        "GR",
        "HU",
        "IE",
        "IT",
        "LV",
        "LT",
        "LU",
        "MT",
        "NL",
        "PL",
        "PT",
        "RO",
        "SK",
        "SI",
        "ES",
        "SE",
    ]

    promptDescriptionCharactersShown=150
}

const Consts = new ConstsClass();

export default Consts;
